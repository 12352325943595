/**
 * Handles if the JS and CSS src need to be replaced with .env variables
 *
 * @param {object} microFrontEndProps The MFE variables to edit
 * @param {object} pageContext The page context that may contain different props
 *
 * @returns {object} microFrontEndProps
 */
export default function handlePageContext(microFrontEndProps, pageContext) {
  // If there are .env variables for js src
  if (pageContext) {
    // If we have a JS Src from the ENV, overwrite the contentful
    // eslint-disable-next-line no-param-reassign
    if (pageContext.jsSrc) microFrontEndProps.jsSrc = pageContext.jsSrc
    // If we have a CSS Src from the ENV, overwrite the contentful
    // eslint-disable-next-line no-param-reassign
    if (pageContext.cssSrc) microFrontEndProps.cssSrc = pageContext.cssSrc
    // Return the MFE Props
  }
  return microFrontEndProps
}

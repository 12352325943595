/* eslint-disable no-console */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { windowExists } from '../../utils'
import { useHasMounted } from '../../utils/hooks'

const Container = styled.div(() => ({
  minHeight: '100vh',
}))

const renderMicroFrontend = (name, config) => {
  // name must be the same as the function used in the micro app
  const functionName = `render${name}`

  if (!windowExists || typeof window[functionName] === 'undefined') {
    console.log('function not found', functionName)
    console.log(
      'function type typeof window[functionName]:`',
      typeof window[functionName]
    )
    return
  }

  window[functionName](`${name}-container`, config) // E.g.: window.renderHorizonSchedule('HorizonSchedule-container');
}

const unmountMicroFrontend = (name) => {
  const functionName = `unmount${name}`

  if (!windowExists || typeof window[functionName] === 'undefined') {
    return
  }

  window[functionName](`${name}-container`)
}

const MicroFrontendContent = ({ cssSrc, jsSrc, name, config }) => {
  useEffect(() => {
    const scriptId = `asc-micro-frontend-${name}`
    if (document.getElementById(scriptId)) {
      renderMicroFrontend(name, config)
      // we need to rebind in order to pass latest props
      document.getElementById(scriptId).onload = renderMicroFrontend.bind(
        null,
        name,
        config
      )
      // returned function will be called on component unmount
      return () => {
        unmountMicroFrontend(name)
      }
    }

    const script = document.createElement('script')
    script.id = scriptId
    script.src = jsSrc
    script.onload = renderMicroFrontend.bind(null, name, config)
    document.head.appendChild(script)

    if (cssSrc) {
      const css = document.createElement('link')
      css.rel = 'stylesheet'
      css.type = 'text/css'
      css.href = cssSrc
      document.head.appendChild(css)
    }

    // returned function will be called on component unmount
    return () => {
      unmountMicroFrontend(name)
    }
  }, [config])

  return <Container id={`${name}-container`} />
}

const MicroFrontend = ({ cssSrc, jsSrc, name, config }) => {
  const hasMounted = useHasMounted()
  if (!hasMounted || !jsSrc) {
    return null
  }
  return (
    <MicroFrontendContent
      cssSrc={cssSrc}
      jsSrc={jsSrc}
      name={name}
      config={config}
    />
  )
}

MicroFrontendContent.propTypes = {
  cssSrc: PropTypes.string,
  jsSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  config: PropTypes.shape({ loggedIn: PropTypes.bool }),
}

MicroFrontend.propTypes = {
  cssSrc: PropTypes.string,
  jsSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  config: PropTypes.shape({ loggedIn: PropTypes.bool }),
}

MicroFrontendContent.defaultProps = {
  cssSrc: '',
  config: {},
}

MicroFrontend.defaultProps = {
  cssSrc: '',
  config: {},
}

export default MicroFrontend

/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../Layout'
import MicroFrontend from '../components/MicroFrontend'
import handlePageContext from '../utils/handlePageContext'

function FringeEvents({ data, location, pageContext }) {
  let microFrontendProps = {
    ...data.contentfulMicroFrontend,
    config: {
      basePath: '/fringe-events',
      location,
    },
  }

  // Handle if there are .env front end props
  microFrontendProps = handlePageContext(microFrontendProps, pageContext)

  return (
    <Layout>
      <MicroFrontend {...microFrontendProps} />
    </Layout>
  )
}

export const fringeQuery = graphql`
  query FringePage($mfInstanceName: String) {
    contentfulMicroFrontend(instanceName: { eq: $mfInstanceName }) {
      instanceName
      name
      jsSrc
      cssSrc
    }
  }
`

FringeEvents.propTypes = {
  data: PropTypes.shape({
    contentfulMicroFrontend: PropTypes.object,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hostname: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    jsSrc: PropTypes.string,
    cssSrc: PropTypes.string,
  }).isRequired,
}

export default FringeEvents
